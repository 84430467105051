import React from 'react';
import ReactDOM from 'react-dom/client';
import { Subject } from 'rxjs';
import * as api from './api';
import App from './App';
import { SCROLL_POS_STORAGE_KEY } from './constants';
// import reportWebVitals from './reportWebVitals';
import store from './eventStore';
import IExternalChannel from './interfaces/IExternalChannel';
import ILobbyOptions from './interfaces/ILobbyOptions';
import ISectionScrollStorage from './interfaces/ISectionScrollStorage';
import ISettings from './interfaces/ISettings';

export const getRootReactElement = (name: string): HTMLElement => {
    let root = document.getElementById(name);
    if (!root) {
        root = document.createElement('div');
        root.setAttribute('id', name);
        document.body.appendChild(root);
    }
    return root;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

let reactRoot: ReactDOM.Root | null = null;

const getReactRootElementId = (rootElementId?: string): string => rootElementId || 'react-lobby-root';

const loadRemoteAssets = (host: string = '') => {
    const preconnectLink: HTMLLinkElement = document.createElement('link');
    preconnectLink.setAttribute('rel', 'preconnect');
    preconnectLink.setAttribute('href', host);
    preconnectLink.setAttribute('crossorigin', '');
    document.head.appendChild(preconnectLink);

    const cssLink: HTMLLinkElement = document.createElement('link');
    cssLink.setAttribute('rel', 'stylesheet');
    cssLink.setAttribute('href', `${host}/assets/Fonts/Roboto/Roboto.css`);
    document.head.appendChild(cssLink);

    const indexCssLink: HTMLLinkElement = document.createElement('link');
    indexCssLink.setAttribute('rel', 'stylesheet');
    indexCssLink.setAttribute('href', `${host}/assets/portal/index.css`);
    document.head.appendChild(indexCssLink);
};

export const reactLobby = (
    rootElementId: string,
    options: ILobbyOptions,
): {
    element: HTMLElement;
    CHANNEL: Subject<IExternalChannel>;
} => {
    const root = getRootReactElement(getReactRootElementId(rootElementId));
    if (options.openType === 'fullscreen') {
        root.classList.add('egt_react_lobby_fullscreen');
    } else {
        root.classList.add('egt_react_lobby_ingame');
    }
    if (options.mode === 'desktop') {
        root.classList.add('egt_react_lobby_desktop');
    } else {
        root.classList.add('egt_react_lobby_mobile');
    }
    store.ON_SOUND_MUTED.next(Boolean(options.mute));
    store.ON_BG_MUSIC_MUTED.next(Boolean(options.bgMusicMute));

    store.COMMUNICATION_CHANNEL.next({
        event: 'balance',
        balance: options,
    });

    const sanitizedOptions: ISettings = {
        ...store.SETTINGS.value,
        playerId: options.playerId,
        gameKey: options.gameKey,
        languageCode: options.languageCode,
        mode: options.mode,
        currency: options.currency,
        units: options.units,
        openType: options.openType,
    };

    // @ts-ignore
    if (window.hasOwnProperty('lobbySsi') && window.lobbySsi.hasOwnProperty('lobbyHost')) {
        // @ts-ignore
        sanitizedOptions.host = window.lobbySsi.lobbyHost;
    } else {
        sanitizedOptions.host = options.host || '';
    }

    // @ts-ignore
    if (window.hasOwnProperty('lobbySsi') && window.lobbySsi.hasOwnProperty('apiIntegrationHost')) {
        // @ts-ignore
        sanitizedOptions.apiHost = window.lobbySsi.apiIntegrationHost;
    } else {
        sanitizedOptions.apiHost = options.apiHost || '';
    }

    // @ts-ignore
    if (window.hasOwnProperty('lobbySsi') && window.lobbySsi.hasOwnProperty('redirectHost')) {
        // @ts-ignore
        sanitizedOptions.redirectHost = window.lobbySsi.redirectHost;
    } else {
        sanitizedOptions.redirectHost = options.redirectHost || '';
    }

    let scrollStorageData;
    try {
        scrollStorageData = localStorage?.getItem(SCROLL_POS_STORAGE_KEY);
    } catch (err) {
        // HINT: Leave it empty as we are not going to do anything
    }
    if (scrollStorageData) {
        try {
            const data = JSON.parse(scrollStorageData) as ISectionScrollStorage;
            sanitizedOptions.selectedSection = data.selectedSection;
            store.SCROLL_STORAGE.next(data);
        } catch (err) {
            // TODO: do nothing
        }
        try {
            localStorage?.removeItem(SCROLL_POS_STORAGE_KEY);
        } catch (err) {
            // HINT: Leave it empty as we are not going to do anything
        }
    }

    store.SETTINGS.next(sanitizedOptions);
    store.INITIALIZE_SOUNDS.next(true);

    api.promissedSettings().then(api.getGamesCurrentPlayersStats);
    api.jackpotStats();

    const reactRootElement = ReactDOM.createRoot(root);
    reactRoot = reactRootElement;
    reactRootElement.render(
        <React.StrictMode>
            <App options={options} />
        </React.StrictMode>,
    );

    loadRemoteAssets(sanitizedOptions.host);

    return {
        element: root,
        CHANNEL: store.EXTERNAL_CHANNEL,
    };
};

export const reactLobbyUnmount = (rootElementId?: string): void => {
    reactRoot?.unmount();

    const rootReactElement: any = getRootReactElement(getReactRootElementId(rootElementId));

    document.body.removeChild(rootReactElement);
};

// @ts-ignore
window.reactLobby = reactLobby;
// @ts-ignore
window.reactLobbyUnmount = reactLobbyUnmount;

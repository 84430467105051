import React, { useEffect } from 'react';
import * as api from './api';
import './App.less';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import LobbyBackgroundGutter from './components/LobbyBackgroundGutter';
import constants from './constants';
import store from './eventStore';
import ILobbyOptions from './interfaces/ILobbyOptions';
import Lobby from './Lobby';
import Providers from './Providers';
import timers, { clearTimer } from './timers';

function App({ options }: IAppProps): JSX.Element {
    useEffect(() => {
        clearTimer(timers.JP_STAT_FETCH);
        timers.JP_STAT_FETCH = setInterval(() => {
            const currency = store.SETTINGS.value?.currency;
            const units = store.SETTINGS.value?.units;
            const isShown = store.SHOW.value;
            if (!currency || !units || !isShown) {
                return;
            }
            api.jackpotStats();
        }, constants.FETCH_JP_STAT_INTERVAL_SECONDS * 1000);

        clearTimer(timers.GAMES_CURRENT_PLAYERS_FETCH);
        timers.GAMES_CURRENT_PLAYERS_FETCH = setInterval(() => {
            api.getGamesCurrentPlayersStats();
        }, constants.FETCH_GAMES_CURRENT_PLAYER_STAT_INTERVAL_SECONDS * 1000);

        return () => {
            clearTimer(timers.JP_STAT_FETCH);
            clearTimer(timers.GAMES_CURRENT_PLAYERS_FETCH);
        };
    }, [options]);

    return (
        <div className="App">
            <BackgroundBlocker />
            <Providers>
                <LobbyBackgroundGutter />
                <LoadingIndicator />
                <Lobby />
            </Providers>
        </div>
    );
}

const BackgroundBlocker = () => {
    return <div className="egtd_background_blocker"></div>;
};

interface IAppProps {
    options: ILobbyOptions;
}

export default App;
